<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <Header />
  <router-view />
  <Footer />
</template>
<script >
import { defineComponent } from 'vue'
import Header from './views/HeaderView.vue'
import Footer from './views/FooterView.vue'    
export default defineComponent({
  setup() {
    // todo
    return {}
  },

  components: { Header,Footer }
})

</script>
<style>
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

html{
  font-size: 39px;
}

html.active {
  overflow: hidden;
  height: auto;
}

body {
  position: relative;
  color: #282b34;
  font-size: 14px;
  font-family: PingFang SC, "微软雅黑", Microsoft YaHei, "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  line-height: 1.6;
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}

.med {
  line-height: normal;
  /* 重点：设置内容行高为 normal */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

td,
th,
caption {
  font-size: 14px;
}

a {
  color: #000;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a {
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

a:visited,
a:focus,
a:hover {
  text-decoration: none;
  color: #333;
}

em {
  font-style: normal;
}

img {
  border: 0;
  vertical-align: middle;
}

img {
  border: none;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea,
select,
button {
  font-size: 14px;
  outline: none;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

video {
  object-fit: contain;
}

.block {
  width: 100%;
  margin: 0 auto;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clear {
  clear: both;
}
body {
  padding-bottom: 2.4rem;
}
</style>

<template>
  <div class="header-box">
    <!-- <img src="../assets/icon_1.png" alt="" /> -->
    {{ titles[0] }}
  </div>
</template>

<script setup>
import { ref } from "vue";
import CryptoJS from "crypto-js";
function Decrypt(str) {
  var KEY = "12345678900000001234567890000000"; //32位
  var IV = "1234567890000000"; //16位
  var key = CryptoJS.enc.Utf8.parse(KEY);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
  var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  var decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
let tmp = Decrypt(
  "e06331f716a412863960f38640a4ca99837a199d4586df13e622899dcd366bf9539634f3dbee8a9c621f18b2dd235eb472d2a64a11fc810dfd432caac2c31f2b4510b31dbcf3cbe800510ec2b3adc89304f75ce3394195fbb714d11798cc7db4194d0bf3cc808023c8cc6714563e65a74572fdc7929b3300be71e67da215b0135506b94d6962cdb78ec8a357b068b42e7b9a5cc2eee41b346c12c18400c37668593dce98f587bee8e6fd3215d07e7f8ca8bea892fb241c6cc2b6112aab49e59f81697736296a32456357fa7422b0d4fea3f33275307d9b3acb79db954126415118cad57ae337076e5b9cb18b817c4b6d41f092f411eb90985a3e71cc8351a05e6413a0f01e12209d0649951eeeac5f9a50496219a51559fc75b949b3d891d37b7c7c75748086db4ab36383a2c4d7e7497ea6e5cd456157f8bdee1feab684be6799d198287587308c5c8e41cf8ed6a47c2a3bc6d9a4bfc2b61c9b153248711358f9f27589582142cdb5c87fafb32733d0816de83be048f8b21277aa04fe816a4efc4002a3b60e8d22b01b795c9aea42e9d833ef35f90fbfb8440acc7bfdd731e3b1721bbec4eecd07d103c768df66c52a54a17c200cc094277f4cf205b854b4f1f64c3dde96e0771d2a56474a3fb39c11fa874292a341b9a0a9547228c8d781d738698533e89c1b3400f564fc6ef182427cec7220c4575280c61135b79c6adc4b49b8fc87d5d1285f3dbbd7c6fdf07ce1992c7c307882c869499cf6b2accb7be13376861dc74b8bb40c982a141c664723ceaa272a918aeeb323b1c357b3c98305bc7eeb021bae5047c1d77ddb96c5eec0"
);

let cmenu = ref(JSON.parse(tmp));
let tmp2 = Decrypt(
  "da87a510e2930843756f649ecdce8b605fae38e78d4da7ac81cb6761c07ce8e1384088a2f9f460f7eed721069aeb09d65babbd211753e22abfc7e8309310dd1dbda6d3d80eb380c072a63765fba0a720"
);

let titles = ref(JSON.parse(tmp2));
document.title = titles.value[0];
console.log(cmenu);
</script>

<style scoped>
.header-box {
  background-color: #fa4e4e;
  height: 1.6rem;
  line-height: 1.6rem;
  padding-left: 0.32rem;
  box-sizing: border-box;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.header-box img {
  height: 1.2rem;
  margin-right: 0.2rem;
}
</style>
<template>
    <div class="footer-box">
        <div class="centent-box">

            <div class="item-box">
                <a href="https://49lh11.com">
                    <div class="icon-box">
                        <img src="../assets/49.png" alt="">
                    </div>
                    <p>网址大全</p>
                </a>
            </div>

            <div class="item-box">
                <a href="">
                    <div class="icon-box">
                        <img src="../assets/icon_2.png" alt="">
                    </div>
                    <p>开通VIP</p>
                </a>
            </div>
            <div class="item-box">
                <a href="">
                    <div class="icon-box">
                        <img src="../assets/icon_3.png" alt="">
                    </div>
                    <p>客户端</p>
                </a>
            </div>
            <div class="item-box">
                <a href="">
                    <div class="icon-box">
                        <img src="../assets/icon_5.png" alt="">
                    </div>
                    <p>消息</p>
                </a>
            </div>
            <div class="item-box">
                <a href="">
                    <div class="icon-box">
                        <img src="../assets/icon_7.png" alt="">
                    </div>
                    <p>登录 | 注册</p>
                </a>
            </div>
            
            <!-- <div class="item-box">
                <a href="">
                    <div class="icon-box">
                        <img src="../assets/icon_6.png" alt="">
                    </div>
                    <p>足迹</p>
                </a>
            </div> -->

        </div>
    </div>
</template>
    
<script >
import { defineComponent } from 'vue'
    
export default defineComponent({
  setup() {
    // todo
    return {}
  }
})
</script>
    
<style  scoped>
.footer-box {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99999;
    bottom: 0;
    background-color: #fa4e4e;
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    height: 1.52rem;
}

.footer-box .centent-box {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-box .centent-box .item-box {
    text-align: center;
    line-height: 1.4;
}

.footer-box .centent-box .item-box .icon-box {
    font-size: 0;
    height: 0.6933rem;
    line-height: 0.6933rem;
} 

.footer-box .centent-box .item-box .icon-box img {
    vertical-align: top;
}

.footer-box .centent-box .item-box p {
    color: #fff;
    font-size: 0.32rem;
}

.footer-box .centent-box .item-box:nth-child(1) .icon-box img {
    height: 0.5733rem;
}

.footer-box .centent-box .item-box:nth-child(2) .icon-box img {
    height: 0.5333rem;
    margin-top: 0.0533rem;
}

.footer-box .centent-box .item-box:nth-child(3) .icon-box img {
    height: 0.7333rem;
    margin-top: -0.08rem;
}

.footer-box .centent-box .item-box:nth-child(4) .icon-box img {
    height: 0.56rem;
    margin-top: 0.0267rem;
}

.footer-box .centent-box .item-box:nth-child(5) .icon-box img {
    height: 0.72rem;
    margin-top: -0.0267rem;
}
</style>
    